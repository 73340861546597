@import '../../assets/scss/variables.scss';

.swanLogin {
    background-color: $color-white;
    width: 100%;
    height: 100%;

    &__info {
        flex: 1;
        max-width: 355px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        padding: 0 32px;

        h1 {
            font-size: 28px;
            margin-bottom: 36px;

        }

        p {
            font-size: 26px;
        }

    }

    &__wrapper {
        width: 100%;
    }

    &__input {
        background-color: $color-gray-light;
        width: 100%;
        border: 0;
        height: 48px;
        border-radius: 8px;
        text-align: center;
        outline: 0;
        font-size: 16px;
        color: $color-black;
        padding: 5px 10px;
        &::placeholder{
            color: $color-gray;
        }
    }

    &__footer {
        flex: 0;
        padding: 33px 15px 31px;
        gap: 10px;

    }

    &__message {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 13px;
        background-color: rgba($color-primary, 9%);
        height: 30px;
        color: $color-primary;
        font-size: 12px;
        font-weight: 600;
        border-radius: 50px;
        max-width: 312px;
        margin: 0 auto;
        width: 100%;
    }
    &__error{
        font-size: 14px;
        margin-top: 2px;
        color: $color-red;
    }
}