@import '../../assets/scss/variables.scss';

.swanHome {
    background-color: $color-white;
    width: 100%;
    height: 100%;
    position: relative;

    @include max-screen($mobile) {
        background-color: $color-primary;
    }

    &__header {
        position: absolute;
        width: 100%;
        z-index: 2;

        @include max-screen($mobile) {
            display: none;
        }

        :global {
            .header-cross {
                color: $color-white !important;
            }
        }
    }

    &__infoMobile {
        display: none;

        @include max-screen($mobile) {
            display: block;
            text-align: center;
        }

        h2 {
            color: $color-white;
            font-size: 24px;
            font-weight: 600;
            margin: 27px 0 0;
            padding: 0 15px;
            text-align: center;
        }

        p {
            color: $color-white;
            font-size: 14px;
            font-weight: 400;
            margin: 4px auto 0;
            line-height: 1.2;
            max-width: 270px;
            text-align: center;
        }
    }

    &__animationMobile {
        display: none;

        @include max-screen($mobile) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 15px;
        }
        canvas{
            max-height: calc(100vh - 350px);
        }
    }

    &__info {
        text-align: center;
        max-width: 280px;
        margin: 0 auto 32px;
        flex: 1;
        width: 100%;

        @include max-screen($mobile) {
            display: none !important;
        }

        p {
            font-size: 16px;
            color: $color-gray-3;
            margin-top: 20px;

        }
    }

    &__btn {
        margin-top: 55px;
        width: 100%;

        p {
            span {
                color: $color-primary;
                font-weight: 600;
            }
        }
    }

    &__footerBtn {
        display: none !important;

        @include max-screen($mobile) {
            display: flex !important;
            gap: 8px;
            margin-top: 20px;
            justify-content: center;
        }
    }
    &__footerBtnCol{
        width: calc(50% - 4px);
        max-width: 140px;
        :global{
            .button{
                @include max-screen($mobile) {
                 font-size: 14px !important;
                }
            }
        }
}
    &__footer {
        flex: 0;
        max-width: 320px;
        margin: 0 auto;
        text-align: center;
        padding: 20px;

        @include max-screen($mobile) {
            margin: 0 7px !important;
            background-color: $color-white;
            border-radius: 20px 20px 0 0;
            box-shadow: 0 0 8px rgba(86, 86, 86, .15);
            max-width: 100%;
            padding: 15px 24px;
        }

        h5 {
            color: $color-gray-3;
            font-size: 12px;
            font-weight: 600;
            @include max-screen($mobile) {
                color: $color-black;
            }
        }

        p {
            color: $color-gray-3;
            font-size: 12px;
            margin-top: 10px;
            @include max-screen($mobile) {
                color: $color-black;
               line-height: 1.5;
            }

            a {
                color: $color-gray-3;
                border-bottom: 1px solid;
                @include max-screen($mobile) {
                    color: $color-black;
                }
            }
        }
    }

    &__headerMobile {
        display: none;

        @include max-screen($mobile) {
            display: block;
            padding: 7px 5px 0;
        }
    }

    &__left {
        width: 50%;
        padding-top: 64px;

        @include max-screen($mobile) {
            width: 100%;
            padding: 0;
        }
    }

    &__right {
        background-color: $color-black;
        width: 50%;

        @include max-screen($mobile) {
            display: none !important;
        }
    }
}