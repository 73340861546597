@import '../../assets/scss/variables.scss';
.swanParent{
    // background-color: rgb(196 196 196 / 20%);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    @include max-screen($mobile) {
        padding: 0;
     }
    &__wrapper{
        width: 100%;
        max-width: 760px;
        max-height: 630px;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 0px 5px rgb(0 0 0 / 3%);
        @include max-screen($mobile) {
           max-width: 100%;
           max-height: 100%;
           border-radius: 0;
        }
    }
}