
// colors
$color-primary: #5932F3;
$color-gray-light: #F5F5F5;
$color-white: #fff;
$color-black: #000;
$color-gray:#8D8D8D;
$color-gray-2:#696969;
$color-gray-3:#475467;
$color-gray-3:#4B4C4E;
$color-red:#ed0000;


// typography
$font-size-base: 16px;
$line-height-base: 1.5;
$font-primary:"Inter", sans-serif;


// breakpoints
$small: 575px;
$mobile: 767px;
$tablet: 991px;
$desktop: 1199px;

// image path
$imgPath: "../../assets/images/";

// mixin
@import 'mixins';