@import '../../assets/scss/variables.scss';
.swanHeader{
    padding: 15px;
    &__cross{
        color: $color-white ;
        width: 24px;
        height: 24px;
        &.blackCross{
            color: $color-black ;
            @include max-screen($mobile) {
                color: $color-primary ;
            }
        }
    }
}