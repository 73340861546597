* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
 
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    font-family: $font-primary;
}

a {
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

img {
    height: auto;
    display: block;
    max-width: 100%;
    font-size: 12px;
}
:focus {
    outline: none;
}
::-ms-expand {
    display: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
[role=button] {
    cursor: pointer;
}
.swan-logo{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: $color-black;
    gap: 13px;
    &__text{
        span{
            font-weight: 400;
        }
    }

}