@import '../../assets/scss/variables.scss';
.swanQr{
    background-color: $color-white; 
    width: 100%;
    height: 100%;

    &__info{
        flex: 1;
 text-align: center;
        text-align: center;
        width: 100%;
        max-width: 339px;
        margin: 0 auto;
h1{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 36px;

}
p{
    font-size: 14px;
    color: $color-gray-2;
    line-height: 1.5;
}

    }
    &__wrapper{
        width: 100%;
    }
   &__scan{
    margin: 20px 0;
   }
}