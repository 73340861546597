
.swan-flex  {
    display: flex;
}
.swan-flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
.swan-flex-column {
    display: flex;
    flex-direction: column;
}
.swan-align-start {
    align-items: flex-start;   
}
.swan-align-center {
    align-items: center;
}
.swan-align-end {
    align-items: flex-end;
}
.swan-justify-between {
    justify-content: space-between;
}
.swan-justify-center {
    justify-content: center;
}
.swan-justify-end {
    justify-content: end;
}
.swan-gap-20{
    gap:20px;
}