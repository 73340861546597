.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    padding: 15px 30px;
    height: 48px;
    font-size: 16px;
    color: $color-primary;
    border: 0;
    font-weight: 600;
    font-family: $font-primary;
    cursor: pointer;
    gap: 7px;
    border-radius: 10px;
    transition: all 0.2s;

    &.sm{
        height: 40px;
    }
    &.blue{
        background-color: $color-primary;
        color: $color-white;
      
    }
   
&:disabled{
   opacity: 0.5;
    cursor: not-allowed;
}
 &.full{
    width: 100%;
 }
 &.outline{
    border: 1px solid $color-primary;
 }
 &.rounded{
    border-radius: 55px;
 }
    &__loader {
	border: 2px solid $color-white;
    border-radius: 50%;
    border-top: 2px solid $color-primary;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;
	}  
 
    &.loading{
        font-size: 0;
        &.contact-button{
            font-size: 16px;
        }
    }
   
}

  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }